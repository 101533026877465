import React from 'react';
import styles from './ListItem.module.css';

const ListItem = ({ identifier, type, statusCode, statusDescription, createdAt, hashURL, value }) => {
  const formatarData = (data) => new Date(data).toLocaleDateString('pt-BR');

  const typeFormmatted = (type) => {
    if (!type || type === "") return "";
    switch (type?.toLowerCase()) {
      case 'abramais':
        return 'Abra Cadabra';
      case 'abracasa':
        return 'Abra Casa';
      case 'fisica':
        return 'Loja Física';
      default:
        return type?.toLowerCase();
    }
  };

  const formatToBRL = (value) => new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value / 100);

  const formatStatusDescription = (statusDescription) => {
    if (!statusDescription) return "";
    switch (statusDescription?.toLowerCase()) {
      case 'Pronto para o manuseio':
        return `👍 ${statusDescription}`;
      case 'Preparando entrega':
        return `📦 ${statusDescription}`;
      case 'Faturado':
        return `🧾 ${statusDescription}`;
      case 'Entregue a transportadora':
        return `🚚 ${statusDescription}`;
      case 'Entregue':
        return `📦 ${statusDescription}`;
      default:
        return formatText(statusDescription);
    }
  };

  const formatText = (text) => {
    if (!text) return "";
    const lowerCased = text.toLowerCase();
    return lowerCased.charAt(0).toUpperCase() + lowerCased.slice(1);
  };

  return (
    <div className={styles.itemContainer}>
      <div className={styles.itemInfo}>
        <div className={styles.mainInfo}>
          <h3>#{identifier}</h3>
          <span className={`${styles.tipo} ${styles[type?.toLowerCase()]}`}>{typeFormmatted(type)}</span>
        </div>
        <div className={styles.details}>
          <span className={`${styles.status} ${styles[statusCode?.toLowerCase()]}`}>
            {formatStatusDescription(statusDescription)}
          </span>
          <span className={styles.data}>{formatarData(createdAt)}</span>
          <span className={styles.data}>{formatToBRL(value)}</span>
        </div>
      </div>
      <a
        href={`${window.location.origin}/pedido/${hashURL}`}
        rel="noopener noreferrer"
        className={styles.button}
      >
        Ver detalhes
      </a>
    </div>
  );
};

export default ListItem; 